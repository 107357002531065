import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'
import FAQs from '../../components/FAQs'
import faqs from '../faqs'

const content = {
  backgroundTitle: 'Other Psychology Services',
  subtitle: `Supervision for psychologists and other allied health
  professionals.`,
  title: 'Supervision',
  otherOptions: [
    {
      text: 'Disability Specialist Services',
      to: '/services/positive-behaviour-support'
    },
    {
      text: 'Mental Health Services',
      to: '/services/mental-health-services'
    }
  ]
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Supervision - Brisbane & Gold Coast Psychology - Upright Performance</title>
      <meta
        name="description"
        content="Rob is a board-approved supervisor that can provide experienced
        supervision to provisionally registered Psychologists as required in the
        5+1 and 4 + 2 pathways."
      />
    </Helmet>

    <Hero
      skew={true}
      bgImage={data.womanWaterfall.childImageSharp}
      height="600px"
      noForm={true}
    >
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Link to="/contact">
          <Button color="#fff" small={true} outline={true}>
            Contact Rob Today
          </Button>
        </Link>
      </Flex>
    </Hero>

    <Content>
      <h2>Board-Approved Provisional Psychologist Supervision</h2>

      <span>
        Rob is a board-approved supervisor that can provide experienced
        supervision to provisionally registered Psychologists as required in the
        5+1 and 4 + 2 pathways.
      </span>

      <p>
        Rob provides evidence-based and best-practice supervision to clinicians
        working in the disability sector. He can support you to complete
        comprehensive Functional Assessments, develop specialist interventions,
        and formulate and implement comprehensive Positive Behaviour Support
        Plans.
      </p>
      <p>
        Navigating and implementing Restricted Practice legislation is an area
        of expertise and Rob’s supervision and consultancy can assist you to
        assess and meet agency and carer legislative requirements.
      </p>

      <Link to="/contact/">
        <Button inverted={true}>Contact Rob Today</Button>
      </Link>
    </Content>

    {/* <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="400px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        View Upright Performance's other Psychology services.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection> */}

    {/* <FAQs data={faqs} flushTop={true} title="Common Supervision Questions" /> */}

    <AboutContact noBorder={true} data={data} />
  </MainLayout>
)
export const query = graphql`
  query SupervisionQuery {
    womanWaterfall: file(relativePath: { eq: "supervision.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "happy-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implant: file(relativePath: { eq: "implant-place.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType: file(relativePath: { eq: "implant-type.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantType2: file(relativePath: { eq: "cut-smooth-implant.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    implantProfile: file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "camping.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
